import React, { Component } from 'react';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import { Card, CardHeader, CardBody } from 'reactstrap';
import ReactSVG from 'react-svg';

import earthiLogo from 'images/earthi.svg';
import savantLogo from 'images/savant.svg';

export default class SignUpPending extends Component {
	render() {
		return (
			<DocumentTitle title="SAVANT | Pending Registration">
				<BodyClassName className="bg-dark">
					<div className="container">
						<Card className="card-login mx-auto mt-5">
							<CardHeader>
								<ReactSVG
									src={earthiLogo}
									className="card-logo"
									svgClassName="earthi-card-logo"
								/>
								<ReactSVG
									src={savantLogo}
									className="card-logo"
									svgClassName="savant-card-logo"
								/>
								<h1 className="sr-only">Pending Registration</h1>
							</CardHeader>
							<CardBody>
								<p>
									Thank you for signing up. Your request has been received and
									you will be notified by email once your request has been
									approved.
								</p>
								<p className="text-center mb-0">
									<a href="/">Close</a>
								</p>
							</CardBody>
						</Card>
					</div>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}
